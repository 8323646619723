import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { a, config, useSpring } from "react-spring"
import { useInView } from "react-intersection-observer"
import { mqTabletHandheld } from "components/index.css"
import { useWindowSize } from "helpers/hooks"
import {
  tabletMaxBP,
  mqHandheld,
  mqTablet,
  BACKGROUND,
  FONT,
} from "components/index.css"
import { H1 as HOne, H2 as HTwo, Section } from "components/typography"
import SEO from "../components/seo"
import S from "components/sticker"

const IndexPage = () => {
  const { width } = useWindowSize()
  const [isOpen, setIsOpen] = useState(false)
  const [stickerRef, stickerInView] = useInView({
    triggerOnce: true,
  })

  useEffect(() => {
    if (tabletMaxBP >= width) {
      setIsOpen(true)
    }
  }, [width])

  const contentProps = useSpring({
    from: { opacity: 0, transform: `translate3d(0, 60px, 0)` },
    to: {
      opacity: isOpen ? 1 : 0,
      transform: `${
        isOpen ? "translate3d(0, 0, 0)" : "translate3d(0, 60px, 0)"
      }`,
    },
  })

  const springProps = useSpring({
    config: config.slow,
    from: {
      opacity: 0,
      transform: `translateX(-20%)`,
    },
    to: {
      opacity: 1,
      transform: `translateX(0px)`,
    },
  })

  function handleMouseEnter() {
    setIsOpen(true)
  }

  return (
    <>
      <SEO />
      <Section>
        <Copy>
          <a.div style={springProps}>
            <Asterisk>&#42;</Asterisk>
            <Headers onMouseEnter={handleMouseEnter}>
              <H1>Servicios de compliance</H1>
              <H2>para la tranquilidad funcional de tu empresa.</H2>
            </Headers>
            <a.div style={contentProps}>
              <P>
                El Compliance no abarca &uacute;nicamente a los sistemas de
                prevenci&oacute;n y supervisi&oacute;n de las empresas para
                prevenir delitos en el seno de las mismas.
              </P>

              <P>
                Existen otros incumplimientos normativos que puede generar
                responsabilidades en el &aacute;mbito civil, laboral o
                administrativo que, llevan aparejadas sanciones que pueden tener
                un gran impacto en tu compa&ntilde;&iacute;a.
              </P>

              <P>
                Queremos ser el partner empresarial que asegure que puedas
                cumplir tus objetivos con total tranquilidad. &iquest;Hablamos?
              </P>
            </a.div>
          </a.div>
        </Copy>
        <StickerWrapper ref={stickerRef}>
          <Sticker side="right" inView={stickerInView}>
            <P>&amp;</P>
            <P>te ayudamos</P>
            <P>con tus</P>
            <P>objetivos</P>
          </Sticker>
        </StickerWrapper>
      </Section>
    </>
  )
}

export default IndexPage

const Copy = styled.div({
  flex: `1 1 70%`,
  maxWidth: 800,
  " > p:last-child": {
    margin: 0,
  },
  [mqTabletHandheld]: {
    flex: `1 1 100%`,
  },
})

const Headers = styled.div({
  lineHeight: `normal`,
  [mqHandheld]: {
    paddingLeft: 2,
  },
})

const H1 = styled(HOne)({
  lineHeight: `77px`,
  boxShadow: `
    inset 0 -0.2em rgb(${BACKGROUND}),
    inset 0 -0.25em ${FONT}
  `,
  display: `inline`,
  textShadow: `
    2px 2px rgb(${BACKGROUND}),
    2px -2px rgb(${BACKGROUND}),
    -2px 2px rgb(${BACKGROUND}),
    -2px -2px rgb(${BACKGROUND})
  `,
  [mqHandheld]: {
    lineHeight: `50px`,
    boxShadow: `none`,
  },
  [mqTablet]: {
    lineHeight: `70px`,
    boxShadow: `none`,
  },
})

const H2 = styled(HTwo)({
  fontSize: 65,
  lineHeight: `77px`,
  fontWeight: 100,

  [mqTablet]: {
    lineHeight: `70px`,
    marginBottom: 35,
  },
  [mqHandheld]: {
    lineHeight: `50px`,
    fontSize: 50,
  },
})

const P = styled.p({
  fontFamily: "Graphik",
  fontSize: 16,
  lineHeight: `20px`,
  marginBottom: 30,

  [mqTablet]: {
    fontSize: 20,
    lineHeight: `28px`,
  },
  [mqHandheld]: {
    lineHeight: `23px`,
    fontSize: 15,
  },
})

const Sticker = styled(S)({
  background: `rgba(235, 195, 46, 1)`,
})

const StickerWrapper = styled.div({
  flex: 1,
  display: `flex`,
  justifyContent: `center`,
  paddingBottom: 10,
  paddingTop: 50,
  [mqTabletHandheld]: {
    paddingTop: 0,
  },
})

const Asterisk = styled(HOne)({
  position: `absolute`,
  top: 0,
  left: -25,
  fontSize: 69,
  lineHeight: `77px`,

  [mqTablet]: {
    left: -25,
    lineHeight: `70px`,
  },
  [mqHandheld]: {
    display: `none`,
  },
}).withComponent(`span`)
